<template>

    <div class="bigBox">
        <div class="zkxmTopBox">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/Icon(1)_1686298472325.png" class="backIcon" @click="toBack">
            <p class="zkxmTopBoxTitle">收藏文章</p>
        </div>
        <div style="height:.88rem"></div>


        <div class="xgyxbox" v-if="articlelist[0]">

            <div class="newsitem" v-for="(item,index) in articlelist" :key="index">
                <router-link :to="{path:`/newsdetail/${item.articleId}`}">
                    <div class="newsitembox">
                        <div class="newsitemleft">
                            <div class="newstitle">{{item.title}}</div>
                            <div class="newstime">
                                <span>来源:</span>
                                <span v-if="item.infoSource.length <= 6">{{item.infoSource}}</span>
                                <span v-else>{{item.infoSource.slice(0,6)}}...</span>
                                <span>时间:</span>
                                <span>{{item.pushTime | dataFilter}}</span>
                            </div>
                        </div>
                        <div class="newsitemright">
                            <img :src="item.widePic">
                        </div>
                    </div>
                </router-link>
            </div>

        </div>

        <div v-else class="noData">
            暂无文章
        </div>



    </div>



</template>

<script>
import Cookies from 'js-cookie'
import {getListColumn,getChildColumn,getArticleAndProject,getListBanner,getlistOnHomepage,getlistOnHomepage2,sms,phoneRegister,listInterestArticle} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store'

export default {

    data(){
        return {
            load : null,
            moviefrom: [],
            wjsToken: null,
            wjsUserInfo: null,
            articlelist:[],
            total:null,
            pageSize:8,
            currPageNo:1,
            articleload:true,
            t : null,
            clientHeight : null,
            scrollHeight : null,
        }
    },
    methods:{

        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        showErr(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '网络错误',
                spinner: 'el-icon-circle-close',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },

        showSuccess(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '成功',
                spinner: 'el-icon-circle-check',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },

        toBack(){
            this.$router.back(-1)
        },

        toLink(name){
            // if(name == "yszb"){
            //     //window.location.href="http://www.zygart.cn"; 
            // }else{
                //this.reset()
                this.$router.push({name:name});
            //}
        },


        windowscroll(){
            this.t = document.documentElement.scrollTop || document.body.scrollTop;
            this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

            if(this.scrollHeight > this.clientHeight && this.t + this.clientHeight + 200  >= this.scrollHeight) {
                if(this.articleload && this.articlelist.length && this.articlelist.length < this.total){

                    this.showLoading()
                    this.articleload = false;
                    this.currPageNo += 1;

                    listInterestArticle({
                        "data": {},
                        "page": {
                            "currPageNo": this.currPageNo,
                            "pageSize": this.pageSize
                        }
                    }).then(res => {
                        this.articlelist = this.articlelist.concat(res.result.records)
                        this.total = res.result.total;
                        this.load && this.load.close();
                    })

                    setTimeout(() => {
                        this.articleload = true;
                    },3000)

                    
                }
                
            }
        },

            

    },
    mounted(){

        this.wjsToken =  Cookies.get('wjsToken') || null;
        this.wjsUserInfo =  Cookies.get('wjsUserInfo') || null;
        this.wjsUserInfo = JSON.parse(this.wjsUserInfo);

        console.log(this.wjsToken)
        console.log(this.wjsUserInfo)

        if(!this.wjsToken){
            this.showErr('请先登录')
            this.toLink('dl')
        }

        this.showLoading()

        this.t = document.documentElement.scrollTop || document.body.scrollTop;
        this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        window.addEventListener('scroll',this.windowscroll)

        listInterestArticle({
            "data": {},
            "page": {
                "currPageNo": this.currPageNo,
                "pageSize": this.pageSize
            }
        }).then(res => {
            this.articlelist = res.result.records;
            this.total = res.result.total;
            this.load && this.load.close();
        })



    },
    filters: {
        movieTypeFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "院线电影"
                    break;
                case 2:
                    return "网络电影"
                    break;
                default:
                    return "error"
            }
        },
        shootStatusFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "未上映"
                    break;
                case 2:
                    return "已上映"
                    break;
                default:
                    return "error"
            }
        },
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },

}




</script>





<style lang="scss" scoped>
.bigBox{
    width: 100%;
    height: 100%;
    background-color: #fff;

    .zkxmTopBox{
        height: .88rem;
        width: 100%;
        position: relative;
        position: fixed;
        top: 0;
        z-index: 999;
        background-color: #fff;
        .backIcon{
            height: .48rem;
            width: .48rem;
            position: absolute;
            top: .2rem;
            left: .4rem;
        }
        .zkxmTopBoxTitle{
            font-size: .34rem;
            color: #343434;
            text-align: center;
            line-height: 0.88rem;
        }
    }

                
    

    .xgyxbox{
        width: 100%;
        padding: 0.2rem 0.22rem 0;
        box-sizing: border-box;
    }
    .xgyxbox .newsitem{
        width: 100%;
        height: 1.84rem;
        padding-bottom: 0.2rem;
        margin-bottom: 0.2rem;
        border-bottom: 0.02rem solid #F4F4F4;
        position: relative;
        .newsitembox{
            height: 1.84rem;
            display: flex;
            justify-content: space-between;
        }
    }
    .xgyxbox .newsitem .newsitemleft{
        position: relative;
        width: 4rem;
    }
    .xgyxbox .newsitem .newsitemleft .newstitle{
        width: 3.9rem;
        font-size: 0.26rem;
        font-weight: 400;
        line-height: 0.4rem;
        color: #343434;
        margin-bottom: 0.1rem;
        display: -webkit-box;    
        -webkit-box-orient: vertical;    
        -webkit-line-clamp: 3;    
        overflow: hidden;

    }
    .xgyxbox .newsitem .newsitemleft .newstime{
        font-size: 0.24rem;
        font-weight: 400;
        line-height: 0.32rem;
        color:#BABABA;
        white-space: nowrap;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .xgyxbox .newsitem .newsitemleft .newstime span{
        display: inline-block;
        transform: scale(.84);
        transform-origin:0 0; 
    }
    .newsitemright{
        width: 2.88rem;
        height: 100%;
        overflow: hidden;
        border-radius: 0.08rem;
    }
    .newsitemright img{
        width: 2.88rem;
        height: 100%;
    }
    .newsxian{
        width: 7rem;
        height: 0.02rem;
        background-color:#F4F4F4;
        margin: 0 auto 0.2rem;
    }


}

.noData{
    font-size: .4rem;
    text-align: center;
    line-height: 3rem;
}


</style>